.centerItems {
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.formControl .dropdownContainer {
  padding: 0;
  padding-bottom: 1.5rem;
}
.formControl .dropdown {
  color: var(--color-text);
  text-transform: uppercase;
  padding: 0;
  min-height: var(--height-button);
}
.formControl .dropdown select {
  text-transform: uppercase;
  padding-left: 24px; /* to compensate for arrow right */
}

.statsRight {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.statsRight .name {
  color: var(--color-active);
  text-transform: uppercase;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.statsRight .icon {
  height: 5rem;
  width: 6rem;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  filter: invert(100%) sepia(6%) saturate(0%) hue-rotate(313deg)
    brightness(105%) contrast(106%);
}
.statsRight .icon.stake {
  background-image: url("../../icons/stake.svg");
}
.statsRight .icon.profitLoss {
  background-image: url("../../icons/profit-loss.svg");
}
.statsRight .icon.margin {
  background-image: url("../../icons/margin.svg");
}
.statsRight .icon.bets {
  background-image: url("../../icons/bets.svg");
}

.statsRight .value {
  color: var(--color-text);
  font-size: 1.5rem;
  line-height: 1em;
  padding-top: 0.5rem;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .centerItems {
    display: flex;
    background-color: var(--color-event-markets);
  }

  .tableRowsGroup {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 4rem;
  }

  .statsRight {
    padding: 0.4rem;
  }

  .statsRight .icon {
    height: 4em;
  }

  .statsRight .value {
    font-size: 1.4em;
  }
}
