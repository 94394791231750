.betswrapper {
  display: flex;
  flex-direction: column;
}
.tableHeader {
  display: flex;
  width: 100%;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.8rem;
  padding: 0.2rem 0.2rem;
  align-items: center;
  text-transform: uppercase;
  color: var(--color-text-secondary);
  position: sticky;
  top: var(--width-main-sidebar-left);
  z-index: 1;
}
.date {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 16%;
  line-height: 1em;
  padding: 0 0.5rem;
}

.date span {
  line-height: 1em;
}

.date span:first-child {
  padding-right: 0.5rem;
}

.date .green {
  color: var(--color-positive);
}

.date .red {
  color: var(--color-negative);
}
.region {
  width: 11%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  line-height: 1em;
}
.competitionName {
  width: 15%;
  text-align: left;
  line-height: 1em;
}
.eventName {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1em;
}
.noBreak {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  word-break: keep-all;
}

.betsNumber {
  width: 5%;
  text-align: center;
}

.stake,
.profit {
  text-align: center;
  width: 5%;
}

.green {
  color: var(--color-positive);
}

.red {
  color: var(--color-negative);
}

.wrapperButtons {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 18%;
  justify-content: center;
}

.wrapperButtons.isBotsPage {
  width: 9%;
}

.settleBet {
  width: 50%;
  display: flex;
}

.plGrid {
  width: 50%;
  display: flex;
}

.plGrid.isBotsPage {
  width: 100%;
}

.plGrid button:hover,
.settleBet button:hover {
  background-color: var(--color-positive);
  color: var(--color-background-main);
}

.btnSpacer {
  width: 5%;
}

.eventLine .btnLink {
  text-align: center;
  background-color: var(--color-background-header);
  padding: 0.2em;
  color: var(--color-positive);
  width: 90%;
  border-radius: 4px;
  border: 1px solid var(--color-background-main);
}
.eventLine:hover .btnLink {
  border: 1px solid var(--color-positive);
}

.eventLine .btnLink.secondary {
  background-color: transparent;
  border-color: var(--color-text-secondary);
  color: var(--color-text-secondary);
}
.eventLine:hover .settleBet button.secondary:hover {
  background-color: var(--color-positive);
  border-color: var(--color-positive);
  color: var(--color-background-main);
}

.expandIcon {
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapperEvent {
  display: flex;
  width: 100%;
  font-size: 0.85em;
  flex-direction: column;
}
.wrapperEvent.expanded {
  border-bottom: 1px solid var(--color-active);
  padding-bottom: 1rem;
}
.wrapperEvent .eventLine {
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  padding: 0.2rem 0.2rem;
  background-color: var(--color-score-type-container);
  border-bottom: 1px solid var(--color-background-main-selected);
}
.wrapperEvent .eventLine:hover {
  background-color: var(--color-background-header);
}
.wrapperEvent.expanded .eventLine,
.wrapperEvent.expanded .eventLine:hover {
  color: var(--color-text);
  background-color: var(--color-active);
}

.region .flag {
  padding-right: 0.2rem;
}
.eventLine .spacer {
  padding: 0 0.5rem;
  text-align: center;
}
.eventLine .home {
  width: 40%;
  text-align: right;
}
.eventLine .away {
  width: 40%;
  text-align: left;
}

.eventLine:hover .expandIcon {
  color: var(--color-positive);
}

.zeroBetsContainer {
  min-height: 10rem;
  color: var(--color-text-secondary);
  background-color: var(--color-event-markets);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zeroBetsContainer .text {
  opacity: 0.5;
}

.zeroBetsContainer .big {
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.2em 0;
}

.loadingParent {
  position: relative;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapperEventLoading {
  display: flex;
  flex-direction: column;
  height: 1.8rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.withTitle {
  cursor: help;
}

.grayText {
  color: var(--color-text-secondary);
}

@media (max-width: 1200px) {
  .date {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    width: 10%;
  }

  .date span:first-child {
    padding-bottom: 0.3rem;
  }
}

@media (max-width: 768px) {
  .region {
    width: 14%;
  }

  .competitionName {
    width: 25%;
  }

  .wrapperButtons {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    width: 12%;
  }

  .settleBet {
    width: 100%;
  }

  .plGrid {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .tableHeader .region {
    display: none;
  }

  .date {
    width: 14%;
  }

  .region {
    text-align: center;
    width: unset;
  }

  .regionName {
    display: none;
  }

  .tableHeader .competitionName {
    width: 38%;
  }

  .competitionName {
    width: 32%;
  }

  .eventName {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
  }

  .eventName .home {
    text-align: left;
    width: 100%;
  }

  .eventName .spacer {
    padding: 0;
  }

  .eventName .away {
    text-align: left;
    width: 100%;
  }

  .betsNumber {
    width: 8%;
  }
}
