.mainSidebar {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-sidebar);
  min-height: calc(100vh - 3rem);
  border-right: 1px solid var(--color-background-main);
  width: var(--width-main-sidebar-left);
  position: relative;
  z-index: 6;
}

.stickyItems {
  position: sticky;
  top: 0;
  z-index: 7;
}

.mainSidebar .item {
  display: flex;
  padding: 0.5rem;
  color: var(--color-text-secondary);
  width: var(--width-main-sidebar-left);
  height: var(--width-main-sidebar-left);
}

.mainSidebar .isActive .item,
.mainSidebar .item:hover,
.mainSidebar .item:active {
  color: var(--color-text);
  background-color: var(--color-active);
}

.mainSidebar .item .name {
  align-items: center;
  background-color: var(--color-background-sidebar);
  color: var(--color-text-secondary);
  display: flex;
  height: 100%;
  left: -100%;
  padding-left: 0.5rem;
  position: absolute;
  top: 0;
  width: 8rem;
  z-index: 7;
  visibility: hidden;
  transition: visibility 0s;
}

.mainSidebar .item:hover .name {
  background-color: var(--color-active);
  color: var(--color-text);
  left: var(--width-main-sidebar-left);
  visibility: visible;
  transition-delay: 0.3s;
}

.mainSidebar .isActive {
  color: var(--color-text);
  background-color: var(--color-active);
}

.secondarySidebar {
  background-color: var(--color-background-sidebar);
  border-right: 1px solid var(--color-background-main);
  left: calc(-1 * calc(var(--width-main-sidebar-left) + 8rem));
  bottom: 0;
  position: absolute;
  top: 0;
  width: calc(var(--width-main-sidebar-left) + 8rem);
  z-index: 6;
}

.overlay {
  display: none;
  position: absolute;
  top: 0;
  left: calc(100% + 1px);
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 6;
  bottom: 0;
  width: calc(100vw - 100% - 1px);
}

@media (hover: none) {
  .mainSidebar .item:hover .name {
    display: none;
  }
}

@media (max-width: 768px) {
  .mainSidebar.active .item {
    z-index: 7;
  }

  .mainSidebar.active .secondarySidebar {
    left: 0;
  }

  .mainSidebar.active .overlay {
    display: flex;
  }

  .mainSidebar.active .item .name {
    display: flex;
    left: var(--width-main-sidebar-left);
  }

  .mainSidebar.active .item.isActive .name,
  .mainSidebar.active .item:hover .name {
    background-color: var(--color-active);
    color: var(--color-text);
    display: flex;
    left: var(--width-main-sidebar-left);
  }
}
