.datePicker-custom {
  z-index: 10;
  display: flex;
}

/** custom dark mode for plugin react-datepicker */
.datePicker-custom .react-datepicker .react-datepicker__navigation:focus {
  outline: none;
}
.datePicker-custom .react-datepicker {
  background-color: var(--color-text-secondary);
  color: var(--color-text-secondary);
  padding: 0.3rem;
}
.datePicker-custom .react-datepicker .react-datepicker__header {
  background-color: var(--color-background-sidebar);
}
.datePicker-custom
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__current-month,
.datePicker-custom
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__day-name {
  color: var(--color-text-secondary);
}
.datePicker-custom
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__day {
  color: var(--color-text);
}
.datePicker-custom
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__day:hover,
.datePicker-custom
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day:hover {
  background-color: var(--color-background-sidebar);
}
.datePicker-custom
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day--selected,
.datePicker-custom
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day--selected.react-datepicker__day--in-range,
.datePicker-custom
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__day--selected {
  background-color: var(--color-active);
  font-weight: 700;
}
.datePicker-custom
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day--keyboard-selected,
.datePicker-custom
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__day--keyboard-selected {
  background-color: inherit;
  /* border: 1px solid var(--color-active); */
}
.datePicker-custom
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__day--outside-month {
  opacity: 0.5;
}

.datePicker-custom
  .react-datepicker
  .react-datepicker__month
  .react-datepicker__day--in-range {
  /* background-color: var(--color-active-parent); */
  /* background-color: #216ba5; */
}

.datePicker-custom
  .react-datepicker
  .react-datepicker__month.react-datepicker__month--selecting-range
  .react-datepicker__day--in-range {
  /* background-color: var(--color-active-parent); */
  background-color: #216ba5;
}

.datePicker-custom .react-datepicker .react-datepicker__month {
  /* height: 12rem; */
}
