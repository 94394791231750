.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.competitions .competitionWrapper {
  display: flex;
  width: 100%;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.8rem;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.loadingParent {
  position: relative;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.zeroEventsContainer {
  min-height: 10rem;
  color: var(--color-text-secondary);
  background-color: var(--color-event-markets);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zeroEventsContainer .text {
  opacity: 0.5;
}

.zeroEventsContainer .big {
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.2em 0;
}

.addContainer {
  display: flex;
  justify-content: flex-end;
  border-top: 2px solid var(--color-text-secondary-transparent);
  padding: 1em 0em 2em 2em;
  margin-top: 2rem;
}

.addContainer .btn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-secondary);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.addContainer .btn:hover {
  border-color: var(--color-positive);
}

.addContainer .btnActive {
  color: var(--color-positive);
}
