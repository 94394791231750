.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-event-markets);
  padding: 0.5rem 0.5rem 2rem 0.5rem;
  border-bottom: 1px solid var(--color-active);
}
.wrapperRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.wrapperRight .marketPeriodText {
  padding: 0.2rem 1.5rem 0.2rem 0.5rem;
  padding-right: 1.5rem;
  color: var(--color-text-secondary);
  text-transform: uppercase;
}

.wrapperRight .marketPeriod {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.25rem 1.2rem;
  min-height: var(--height-button-secondary);
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
}
.wrapperRight .marketPeriodActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}
.wrapperRight .marketPeriod:hover {
  border-color: var(--color-active);
}

.wrapperRight .marketType {
  background-color: var(--color-market-period);
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 1rem;
}
.wrapperBetType {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.wrapperBetType .title3 {
  color: var(--color-active);
  line-height: 1.8em;
  padding-top: 0.2em;
  font-weight: 700;
}

.scoreTypeContainer {
  background-color: var(--color-score-type-container);
  display: flex;
  flex-direction: column;
  /* padding: 0.5rem 1rem; */
  margin-bottom: 0.2rem;
}

.scoreTypeContainer .markets {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 1rem;
}

.scoreTypeContainer .btn {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.25rem 1.2rem;
  min-height: var(--height-button-secondary);
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
}

.scoreTypeContainer .btn:hover,
.scoreTypeContainer .btn.marketTypeActive {
  border-color: var(--color-active);
  color: var(--color-text);
}

.scoreTypeContainer .marketTypeActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
}

.scoreTypeContainer .scoreTypeSelectionsContainer {
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  margin-right: 10%;
}
.scoreTypeContainer .scoreTypeSelectionsContainer:last-child {
  margin-right: 0;
}
.scoreTypeContainer .scoreTypeSelectionsContainer.spaceRight {
  margin-right: 2rem;
}
.scoreTypeContainer .scoreTypeText {
  color: var(--color-text-secondary);
  text-transform: uppercase;
  padding-right: 1.5rem;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .scoreTypeContainer .btn {
    padding: 0.25rem 1rem;
  }
}

@media (max-width: 768px) {
  .scoreTypeContainer .markets {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .scoreTypeContainer .btn {
    padding: 0.25rem 0.75rem;
  }
}

@media (max-width: 480px) {
  .wrapper {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .scoreTypeContainer .scoreTypeText {
    padding-right: 0.75rem;
  }
}

@media (max-width: 360px) {
  .wrapperRight .marketPeriodText {
    padding: 0.2rem 0.75rem 0.2rem 0.5rem;
  }
}
