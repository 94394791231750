.flagIconContainer {
  font-size: 1em;
  line-height: 1em;
}

.specialFlag {
  display: inline-block;
  width: 1.33333333em;
  height: 1em;
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.specialFlag.footballIcon {
  background-image: url("../icons/football.svg");
  height: 1.3em;
}
