.wrapper {
  display: flex;
  background-color: var(--color-background-sidebar);
}
.mainContent {
  width: 70%;
  background-color: var(--color-background-main-selected);
  margin-right: 1rem;
  padding: 2rem;
}
.sideRight {
  margin-right: 1rem;
  width: 30%;
  background-color: var(--color-background-main);
}

.filtersContainer {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  margin-left: -0.5rem;
  width: calc(100% + 1rem);
}

.traderRegionCompetitionContainer,
.datePickerContainer,
.viewTypeContainer,
.marketPeriodContainer,
.scoretypeContainer,
.reportsbtnContainer,
.bookieContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.noSpaceLeft {
  padding-left: 0;
  padding-right: 0;
}

.traderRegionCompetitionContainer {
  width: calc(3 * 10rem);
}

.marketPeriodContainer .marketPeriod {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
}

.marketPeriodContainer .marketPeriodActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}

.scoretypeContainer .scoreType {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
}

.scoretypeContainer .scoreTypeActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}

.viewTypeContainer .viewType {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
  height: 2rem;
}

.viewTypeContainer .viewType:hover,
.marketPeriodContainer .marketPeriod:hover,
.scoretypeContainer .scoreType:hover {
  border-color: var(--color-active);
}

.viewTypeContainer .viewTypeActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}

.reportsbtnContainer .getReportBtn {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
  border: 1px solid;
}

.reportsbtnContainer .getReportBtn:hover {
  background-color: var(--color-market-period);
  border-color: var(--color-market-period);
}

.footerNote {
  margin-top: 4rem;
  color: var(--color-text-secondary);
  font-style: inherit;
}

@media (max-width: 1200px) {
  .wrapper {
    display: inline;
  }

  .mainContent {
    width: 100%;
    margin-right: 0;
  }

  .filtersContainer {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .traderRegionCompetitionContainer {
    flex-wrap: wrap;
    width: 100%;
  }

  .datePickerContainer {
    flex-wrap: wrap;
  }

  .viewTypeContainer {
    flex-wrap: wrap;
  }

  .marketPeriodContainer {
    flex-wrap: wrap;
  }

  .scoretypeContainer {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .mainContent {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .mainContent {
    padding: 0.5rem;
  }
}
