.title {
  color: var(--color-active);
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  align-items: baseline;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.title .main {
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0;
}
.title .subtitle {
  /* font-size: 1.5rem;
  line-height: 1em; */
  font-weight: 700;
}

.eventsWrapper .eventInfoFirstLine {
  color: var(--color-text);
}

.spacer {
  width: 0.5em;
}

.eventLine {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.eventLine:hover {
  background-color: var(--color-background-main-selected);
}

.eventLine .eventHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: unset;
  text-transform: unset;
  padding: 0.4em 0.4em;
  background-color: var(--color-event-markets);
  border-bottom: 1px solid var(--color-background-sidebar);
}

.eventHeader .competitionName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35%;
  color: var(--color-active);
  text-align: left;
}

.eventLine .eventHeader .spaceRight {
  padding-right: 0.4em;
}

.eventHeader .competitors {
  color: var(--color-text);
  flex-grow: 1;
}

.eventHeader .nBets {
  color: var(--color-active);
  min-width: 1.5em;
  padding: 0.2em;
}

.eventHeader.isOpen .nBets,
.eventHeader:hover .nBets {
  background-color: var(--color-background-sidebar);
  border-radius: 4px;
}

.eventLine .eventHeader.isOpen,
.eventLine .eventHeader:hover {
  background-color: var(--color-active);
}

.eventHeader.isOpen .competitionName,
.eventHeader:hover .competitionName {
  color: var(--color-background-sidebar);
}

.eventHeader .expandIcon {
  display: flex;
  align-items: center;
  padding-left: 0.2em;
  color: var(--color-text);
}

.betLine {
  padding: 0.5rem 0.5rem;
  font-size: 0.9em;
  width: 100%;
  border-bottom: 1px solid var(--color-background-main-selected);
}

.betLineRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.betLine .marketPeriod {
  text-transform: uppercase;
  color: var(--color-active);
  padding-right: 0.5em;
}

.betLine .betType {
  font-weight: 700;
  padding-right: 0.5em;
}
.betLine .marketProvider {
  font-weight: 700;
  padding-right: 1em;
}
.betLine .betHandicap {
  flex-grow: 1;
}
.betLine .betHandicapType {
  color: var(--color-text-secondary);
}
.betLine .handicap {
  color: var(--color-text-secondary);
  padding-right: 0.2rem;
}
.betLine .handicapSelected {
  text-align: start;
}

.betLine .deleteBtn {
  text-transform: uppercase;
  padding: 0.2em 0.8em;
  background-color: var(--color-background-header);
  color: var(--color-negative);
  border-radius: 4px;
}

.betLine .deleteBtn:hover {
  background-color: var(--color-negative);
  color: var(--color-background-header);
}

.betLineRow .line {
  color: var(--color-text-secondary);
  padding-right: 0.2rem;
}

.betLineRow .lineNumber {
  text-align: start;
}

.betLineRow .price {
  color: var(--color-text-secondary);
  padding-right: 0.2rem;
}

.betLineRow .priceNumber {
  text-align: start;
}

.betLineRow .stake {
  color: var(--color-text-secondary);
  padding-right: 0.2rem;
}

.betLineRow .stakeNumber {
  text-align: start;

  flex-grow: 1;
}

.betLineRow .value {
  color: var(--color-text-secondary);
  padding-right: 0.2rem;
}

.betLineRow .valueNumber {
  text-align: start;
}

.zeroBetsContainer {
  min-height: 10rem;
  color: var(--color-text-secondary);
  background-color: var(--color-event-markets);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zeroBetsContainer .text {
  opacity: 0.5;
}

.zeroBetsContainer .big {
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.2em 0;
}
.green {
  color: var(--color-positive);
}
.red {
  color: var(--color-negative);
}
