.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);
  width: 100%;
  padding: 1rem;
}

.title {
  color: var(--color-active);
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  align-items: baseline;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.title .main {
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0;
}
.title .subtitle {
  font-size: 1.2em;
  font-weight: 700;
}

.settleMatchContainer {
  background-color: var(--color-background);
}

.inputContainer {
  padding-left: 0;
  padding-right: 0;
  width: 30%;
}

.hideArrows input[type="number"]::-webkit-inner-spin-button,
.hideArrows input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.optionsWrapper {
  display: flex;
  flex-direction: row;
}

.optionContainer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.option {
  color: var(--color-active);
  padding-bottom: 0.25em;
}

.wrapper .formWrapper {
  padding-bottom: 0.5em;
  position: relative;
}

.formWrapper .loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.formWrapper .loadingContainer .loadingText {
  color: var(--color-active);
  position: absolute;
  bottom: 0;
}

.warningContainer {
  padding: 0.5rem;
  background-color: var(--color-background-main-selected);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.warningContainer .infoRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.warningContainer .icon,
.btnsContainer .icon {
  margin-right: 0.5rem;
}

.warningContainer .profitLoss {
  padding: 0 0.4em;
  font-size: 1.2em;
}
.profitLoss.profit,
.icon.profit {
  color: var(--color-positive);
}
.profitLoss.loss,
.icon.loss {
  color: var(--color-negative);
}

.bottomContainer {
  display: flex;
  border-top: 4px solid var(--color-background-main-selected);
  padding: 1em 0;
  flex-direction: column;
}

.text .profit {
  color: var(--color-active);
}

.btnsContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.warningContainer .btnsContainer {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btnsContainer button {
  margin-right: 1em;
}
.btnsContainer button:last-child {
  margin-right: 0;
}

.btnsContainer .btnCancel {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-miscellaneous);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnsContainer .btnCancel:hover {
  border-color: var(--color-text-miscellaneous);
}

.btnsContainer .saveBtn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-secondary);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnsContainer .saveBtn:hover {
  border-color: var(--color-positive);
}

.btnsContainer .saveBtnActive {
  color: var(--color-positive);
}

.hide {
  visibility: hidden;
}

.relative {
  position: relative;
  width: 100%;
}

.pair {
  display: flex;
  align-items: center;
  width: 100%;
}

.settleName {
  padding: 0 1rem;
  flex-grow: 1;
  text-align: center;
  color: var(--color-text-secondary);
}

.type {
  flex-direction: column;
  padding-bottom: 0.5em;
}

.settlementHeader {
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  color: var(--color-active);
  font-size: 1.5em;
}

.settlementEvent {
  padding-left: 2rem;
  color: var(--color-active);
  font-size: 1em;
}
