.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);
  width: 100%;
  padding: 1rem;
  min-width: 70vw;
}

.header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.filters {
  display: flex;
  flex-wrap: wrap;
}

.title {
  color: var(--color-active);
  display: flex;
  /* width: 100%; */
  min-height: 1.8rem;
  align-items: baseline;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.title .main {
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0;
}
.title .subtitle {
  font-size: 1.2em;
  font-weight: 700;
}

.titleText {
  color: var(--color-active);
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1em;
  flex-grow: 1;
}

.periodSelection {
  padding: 0 1rem;
}
.scoreTypeSelection {
  padding-left: 1rem;
}

.periodSelection .periodSelected,
.scoreTypeSelection .scoreTypeSelected {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.25rem 1.2rem;
  min-height: var(--height-button-secondary);
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
}

.periodSelection .periodSelected:hover,
.scoreTypeSelection .scoreTypeSelected:hover {
  border-color: var(--color-active);
}

.periodSelection .periodSelectedActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}

.scoreTypeSelection .scoreTypeSelectedActive {
  background-color: var(--color-background-header);
  border-color: var(--color-active);
  color: var(--color-text);
}

.content {
  border-bottom-style: solid;
  border-bottom-color: var(--color-background-main-selected);
  padding-bottom: 1em;
}

.grid {
  display: flex;
  width: 100%;
}

.grid .eachRow {
  width: 100%;
  display: flex;
  align-items: center;
}
.eachRow .eachValue {
  background-color: var(--color-negative-grid);
  width: calc(10% - 2px);
  min-width: 4rem;
  margin: 1px;
  height: 1.8rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1em;
}

.HArowContainer {
  flex-grow: 1;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* deixa a rolagem suave */
}

.HArow {
  width: 100%;
  display: flex;
  align-items: center;
}

.HArow .HAeachValue {
  width: calc(10% - 2px);
  margin: 1px;
  min-width: 4rem;
  height: 1.8rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1em;
  background-color: var(--color-background-sidebar);
}

.HAcolumn {
  width: 10%;
  min-width: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.HAcolumn .HAeachValue {
  width: calc(100% - 2px);
  margin: 1px;
  min-width: 4rem;
  height: 1.8rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1em;
  background-color: var(--color-background-sidebar);
}

.grid .eachValuePositive {
  background-color: var(--color-positive-grid);
}

.grid .eachValueNeutral {
  background-color: var(--color-background-main-selected);
}

.eachRow .eachValue:hover,
.grid .hovered {
  background-color: var(--color-active);
}
.grid .result {
  border: 4px solid var(--color-active);
}

.btnCloseContainer {
  display: flex;
  justify-content: flex-end;
  border-top: 4px solid var(--color-background-main-selected);
  padding: 1em 0em 2em 2em;
}
.btnCloseContainer .btnCancel {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-miscellaneous);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnCloseContainer .btnCancel:hover {
  border-color: var(--color-text-miscellaneous);
}

@media (max-width: 768px) {
  .header .titleText {
    align-items: flex-start;
    grid-area: title;
    height: 100%;
  }

  .header .periodSelection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 0 0.5rem 0;
    width: 100%;
  }

  .header .scoreTypeSelection {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0;
    width: 100%;
  }
}
