.wrapperEvent {
  display: flex;
  width: 100%;
  font-size: 0.85em;
  flex-direction: column;
}
.wrapperEvent.expanded {
  border-bottom: 1px solid var(--color-active);
  padding-bottom: 1rem;
}
.wrapperEvent .eventLine {
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  background-color: var(--color-event-markets);
  border-bottom: 1px solid var(--color-background-main-selected);
}
.wrapperEvent .eventLine:hover {
  background-color: var(--color-active-hover);
}
.wrapperEvent.expanded .eventLine {
  background-color: var(--color-active);
  color: var(--color-text);
}
.wrapperEvent.expanded .eventLine:hover {
  background-color: var(--color-active-hover);
}

.eventLine .event {
  flex-grow: 1;
  text-align: center;
}
.eventLine .eventStake {
  text-align: center;
  width: 12%;
}
.eventLine .eventProfit {
  text-align: center;
  width: 14%;
}
.eventLine .eventMargin {
  text-align: center;
  width: 12%;
}
.eventLine .eventBets {
  text-align: center;
  width: 8%;
}
.eventLine .expandIcon {
  text-align: right;
  width: 6%;
}
.eventLine .spacer {
  padding: 0 0.5rem;
  text-align: center;
}
.eventLine .home {
  width: 40%;
  text-align: right;
}
.eventLine .away {
  width: 40%;
  text-align: left;
}

.eventLine:hover .expandIcon {
  color: var(--color-positive);
}

.green {
  color: var(--color-positive);
}
.red {
  color: var(--color-negative);
}

@media (max-width: 768px) {
  .eventLine .eventStake {
    width: 14%;
  }
  .eventLine .eventProfit {
    width: 18%;
  }
  .eventLine .eventMargin {
    width: 14%;
  }
  .eventLine .eventBets {
    width: 12%;
  }
  .eventLine .event {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    padding: 0.25em 0 0.25em 0.25em;
  }
  .eventLine .spacer {
    padding: 0;
  }
  .eventLine .home {
    text-align: left;
    width: 100%;
  }
  .eventLine .away {
    width: 100%;
  }
}

@media (max-width: 410px) {
  .eventLine .event {
    max-width: 35%;
  }
}
