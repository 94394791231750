.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);
  width: 100%;
  padding: 1rem;
}

.header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.title {
  color: var(--color-active);
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  align-items: baseline;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.title .main {
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0;
}

.content {
  border-bottom-style: solid;
  border-bottom-color: var(--color-background-main-selected);
  padding-bottom: 1em;
}

.content .row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.content .row .width50 {
  width: calc(50% - 0.5rem);
}

.content .label {
  color: var(--color-active) !important;
  padding-bottom: 0.25em;
}

.btnCloseContainer {
  display: flex;
  justify-content: flex-end;
  border-top: 4px solid var(--color-background-main-selected);
  padding: 1em 0em 2em 2em;
}

.btnCloseContainer .btn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
  margin: 0 0.5rem;
}
.btnCloseContainer .btn:first-child {
  margin-left: 0;
}
.btnCloseContainer .btn:last-child {
  margin-right: 0;
}

.btnCloseContainer .btn.btnCancel {
  color: var(--color-text-miscellaneous);
}

.btnCloseContainer .btn.btnCancel:hover {
  border-color: var(--color-text-miscellaneous);
}

.btnCloseContainer .btn.saveBtn {
  color: var(--color-positive);
}
.btnCloseContainer .btn.saveBtn.isDisabled {
  color: var(--color-text-secondary);
}

.btnCloseContainer .btn.saveBtn:hover {
  background-color: var(--color-background-header);
  border-color: var(--color-positive);
}

.btnCloseContainer .btn.saveBtn svg {
  font-size: 1.5em;
  margin-right: 0.25rem;
}

/* START Select Competition */
.addCompetition {
  margin-bottom: 1.5rem;
  width: 100%;
}

.inputAutoCompleteContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.inputAutoCompleteContainer .inputText {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
  width: 100%;
}

.inputAutoCompleteContainer .inputText input {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
  padding-left: 0.5rem;
}

.inputAutoCompleteContainer .inputText svg {
  color: var(--color-text);
}

.inputAutoCompleteContainer.invalid .inputText input {
  color: rgb(255, 255, 255);
  background-color: red;
}

.listbox,
.listbox div {
  background-color: var(--color-background-main);
  color: var(--color-text);
  max-height: 60vh !important;
}

.listbox li ul li:hover,
.listbox li ul li:focus {
  background-color: var(--color-background-sidebar);
}
.listbox .groupTitle {
  line-height: 1em;
  padding-top: 1em;
  padding-bottom: 0.5em;
  text-transform: uppercase;
}
.listbox .flag {
  padding-right: 0.5rem;
}

.inputAutoCompleteContainer .inputText.optionListbox:hover {
  background-color: var(--color-positive);
}

.inputAutoCompleteContainer .inputText .inputProps:hover::before,
.inputAutoCompleteContainer .inputText .inputProps:active::before,
.inputAutoCompleteContainer .inputText .inputProps:focus::before,
.inputAutoCompleteContainer .inputText .inputProps:hover::after,
.inputAutoCompleteContainer .inputText .inputProps:active::after,
.inputAutoCompleteContainer .inputText .inputProps:focus::after {
  border-bottom-color: var(--color-active) !important;
}

.inputAutoCompleteContainer .inputText .inputProps:-webkit-autofill,
.inputAutoCompleteContainer .inputText .inputProps:-webkit-autofill:hover,
.inputAutoCompleteContainer .inputText .inputProps:-webkit-autofill:focus {
  border: 1px solid green;
  -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* END Select Competition */

/* START Select Team */
.addTeam {
  margin-bottom: 0.5rem;
  width: 100%;
}
/* END Select Team */

/* START Select Date time */
.eventDate {
  margin-bottom: 1.5rem;
}
.eventDate .datetimeInput {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
}
.eventDate .datetimeInput input::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}
.eventDate .underline:after {
  border-bottom-color: var(--color-active) !important;
}
/* END Select Date time */

.textNote {
  color: var(--color-text-secondary);
  font-size: 0.85em;
  padding: 0.5em;
}

.row.alignRight {
  justify-content: flex-end;
  align-items: baseline;
}

.row .btnAddCompetitor {
  background-color: var(--color-background-sidebar);
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
}
.row .btnAddCompetitor:hover {
  background-color: var(--color-text-secondary);
  color: var(--color-text);
}
