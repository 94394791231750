.body {
  display: flex;
  background-color: var(--color-background-sidebar);
  width: 100%;
}

.center {
  display: flex;
  width: calc(100% - var(--width-main-sidebar-left));
  flex-direction: column;
}
