.tableReports {
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}

.tableHead {
  display: flex;
  width: 100%;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.8rem;
  align-items: center;
  text-transform: uppercase;
  color: var(--color-text-secondary);
}

.tableLine {
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  font-size: 0.9em;
  background-color: var(--color-score-type-container);
  border-bottom: 1px solid var(--color-background-main-selected);
}
.tableLine:hover {
  background-color: var(--color-background-header);
}

.lineName {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineStake {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lineProfit {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineMargin {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lineBets {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingParent {
  position: relative;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.green {
  color: var(--color-positive);
}
.red {
  color: var(--color-negative);
}

.lineBets .btn {
  border: 1px solid var(--color-positive);
  border-radius: 4px;
  font-size: 0.8rem;
  min-height: 90%;
  padding: 0.25rem 0.5rem;
  min-width: 40%;
  max-width: 100%;
}

.lineBets .btn:hover,
.lineBets .btn.active {
  background-color: var(--color-positive);
  color: var(--color-text);
}

.lineBets .btn.disabled {
  border-color: transparent;
}
