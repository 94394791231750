.mainTabs {
  display: flex;
  background-color: var(--color-background-sidebar);
  position: sticky;
  top: 0;
  z-index: 5;
}

.mainTabs .item {
  display: flex;
  padding: 0.5rem 1rem;
  color: var(--color-text-secondary);
  height: var(--width-main-sidebar-left);
  text-transform: uppercase;
  font-size: 1.2em;
}

.mainTabs .isActive .item,
.mainTabs .item:hover,
.mainTabs .item:active {
  color: var(--color-active);
  background-color: var(--color-background-main-selected);
}

.mainTabs .isActive .item {
  font-weight: 700;
}
.mainTabs .isActive {
  color: var(--color-active);
  background-color: var(--color-background-main-selected);
  font-weight: 700;
}
