* {
  box-sizing: border-box;
}

html,
body {
  font-size: 0.825rem;
  margin: 0;
  line-height: 1;
}

html {
  background-color: var(--color-background-main);
}

body {
  height: 100%;
  min-width: 320px;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background: var(--color-background-main);
  color: var(--color-text);
}

.d-none {
  display: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
}

button {
  font: inherit;
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h4,
h5,
h6,
input,
legend,
li,
ol,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
  line-height: 1.2em;
}

li {
  list-style: none;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/****** LOADING elements - START ******/
.loading-gradient {
  width: 100%;
  background-size: 200% 100% !important;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.15) 0%,
    rgba(255, 255, 255, 0.35) 50%,
    rgba(255, 255, 255, 0.15) 100%
  ) !important;
  animation-duration: 2s;
  animation-name: GradientSlide;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes GradientSlide {
  0% {
    background-position: 0% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}

/****** LOADING elements - END ******/

/****** LOADER circle - START ******/
.loader-circle {
  /* set font-size on parent element to control the size of this loader circle */
  border: 0.75em solid transparent;
  border-top: 0.75em solid var(--color-active);
  border-bottom: 0.75em solid var(--color-active);
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 1em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/****** LOADER circle - END ******/

/****** MOBILE TABLET display none - START ******/
@media screen and (min-width: 1201px) {
  .lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 481px) and (max-width: 1200px) {
  .md-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

@media screen and (max-width: 480px) {
  .xs-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

/****** MOBILE TABLET display none - END ******/

@supports (-webkit-overflow-scrolling: touch) {
  /** prevent zoom in on inputs on mobile devices **/
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

/****** LOADER circle - START ******/
.loader-circle {
  /* set font-size on parent element to control the size of this loader circle */
  border: 0.75em solid transparent;
  border-top: 0.75em solid var(--color-active);
  border-bottom: 0.75em solid var(--color-active);
  border-radius: 50%;
  width: 5em;
  height: 5em;
  margin: 1em;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/****** LOADER circle - END ******/
