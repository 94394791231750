.modalHelper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8;
  display: flex;
  align-items: flex-start;
  padding-top: 1.5rem;
  justify-content: center;
}

.modalContent {
  z-index: 7;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  min-width: 40%;
  max-height: 90vh;
  overflow-y: auto;
  background-color: var(--color-background);
  color: var(--color-main-text);
  box-shadow: 0 4px 8px 0 rgba(255, 255, 255, 0.1);
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 6;
}
