:root {
  --color-background-header: #000000;
  --color-background-sidebar: #16191c;
  --color-background-main: #1c2023;
  --color-background-main-selected: #22272b;
  --color-text: #ffffff;
  --color-text-secondary: #858585;
  --color-text-secondary-transparent: rgba(133, 133, 133, 0.4);
  --color-text-miscellaneous: #696417;
  --color-active: #00acee;
  --color-active-hover: #009ddb;
  --color-active-parent: #0079a8;
  --color-active-parent-hover: #01658d;
  --color-positive: #27cf2b;
  --color-positive-grid: rgb(88, 139, 102);
  --color-negative: #bb2012;
  --color-negative-grid: rgb(180, 87, 78);
  --width-main-sidebar-left: 3rem;
  --color-event-markets: #1e2a30;
  --color-market-period: #0d1216;
  --color-score-type-container: #151e22;
  --height-button: 2.5rem;
  --height-button-secondary: 2rem;
  --color-yellow: #fce205;
  --color-home: #00aef1;
  --color-home-changed: #00d9ff;
  --color-away: #00b956;
  --color-away-changed: #00ff77;
  --color-orange: #ff7b00;
}
