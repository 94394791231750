.loadingParent {
  position: relative;
  height: 20em;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}
