.betsByCompetition {
  color: var(--color-active);
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: var(--color-active);
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  align-items: baseline;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.title .main {
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0;
}

.title .subtitle {
  font-weight: 700;
}

.stickyContainer {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.stickyText {
  font-size: 0.8rem;
}

.hide {
  display: none;
}

.stickyContainer .root {
  height: 36px;
  padding: 12px;
  width: 52px;
}

.stickyContainer .switchBase {
  color: var(--color-active);
  padding: 13px;
}

.stickyContainer .switchBase.checked {
  color: var(--color-background-header);
  transform: translateX(15px);
}

.stickyContainer .thumb {
  height: 10px;
  width: 10px;
}

.stickyContainer .track {
  background-color: var(--color-background-sidebar);
  opacity: 1;
}

.stickyContainer .switchBase.checked + .track {
  background-color: var(--color-active);
  opacity: 1;
}

.betsByCompetitionWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: unset;
  text-transform: unset;
  padding: 0.4em 0.4em;
  background-color: var(--color-event-markets);
  border-bottom: 1px solid var(--color-background-sidebar);
  min-height: 1.8rem;
}

.betsByCompetitionWrapper .flag {
  /* width: 5%; */
  padding-right: 0.4em;
}

.betsByCompetitionWrapper .competitionName {
  width: 73%;
}

.betsByCompetitionWrapper .betsLength {
  text-align: right;
  width: 22%;
}

.zeroBetsContainer {
  min-height: 10rem;
  color: var(--color-text-secondary);
  background-color: var(--color-event-markets);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zeroBetsContainer .text {
  opacity: 0.5;
}

.zeroBetsContainer .big {
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.2em 0;
}