.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid var(--color-background-main-selected);
  background-color: var(--color-score-type-container);
}

.competitionEvents .eventsWrapper {
  display: flex;
  min-height: 1.6rem;
  font-size: 0.9em;
  width: 100%;
  color: var(--color-text-secondary);
}
.competitionEvents .eventsWrapper:hover {
  color: var(--color-text);
  background-color: var(--color-background-header);
}
.competitionEvents .eventActive,
.competitionEvents .eventActive:hover {
  color: var(--color-text);
  background-color: var(--color-active);
}

.eventsWrapper .date {
  width: 14%;
}

.eventsWrapper .hour {
  width: 8%;
}

.eventsWrapper .state {
  width: 10%;
}

.state .green {
  color: var(--color-positive);
}

.state .red {
  color: var(--color-negative);
}

.eventsWrapper .event {
  flex-grow: 1;
  display: flex;
}
.eventsWrapper .event .spacer {
  padding: 0 1rem;
}
.eventsWrapper .event .home {
  width: 50%;
  text-align: right;
}
.eventsWrapper .event .away {
  width: 50%;
  text-align: left;
}
.eventsWrapper .expandIcon {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}
.competitionEvents .eventsWrapper:hover .expandIcon {
  color: var(--color-positive);
}

@media (max-width: 480px) {
  .eventsWrapper .date {
    width: 14%;
  }

  .eventsWrapper .hour {
    width: 12%;
  }

  .eventsWrapper .state {
    width: 16%;
  }

  .eventsWrapper .event {
    width: 50%;
  }

  .eventsWrapper .event .spacer {
    padding: 0 0.5rem;
  }

  .eventsWrapper .expandIcon {
    padding: 0;
  }
}

@media (max-width: 410px) {
  .eventsWrapper .date {
    display: none;
  }

  .eventsWrapper .hour {
    width: 16%;
  }

  .eventsWrapper .state {
    width: 20%;
  }

  .eventsWrapper .event {
    width: 56%;
  }
}
