.wrapper {
  display: flex;
  background-color: var(--color-background-sidebar);
}
.mainContent {
  width: 70%;
  background-color: var(--color-background-main-selected);
  margin-right: 1rem;
  padding: 2rem;
}
.sideRight {
  margin-right: 1rem;
  width: 30%;
  background-color: var(--color-background-main);
  padding: 1rem 0.5rem;
}

.isSticky {
  max-height: calc(100vh - var(--width-main-sidebar-left));
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  position: sticky;
  top: var(--width-main-sidebar-left);
}

.isSticky::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
}

.filtersContainer .traderContainer {
  padding: 0 0.5rem 0.5rem 0.5rem;
  min-width: 8rem;
}

.filtersContainer .dateSelection {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.filtersContainer .btnStatusWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.filtersContainer .btnAutoSettleWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.btnAutoSettleWrapper .btnAutoSettle {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
}

.btnAutoSettleWrapper .btnAutoSettle:hover {
  border-color: var(--color-active);
}

.numberEventsToSettle {
  align-items: center;
  /* align-self: flex-start; */
  background-color: var(--color-active);
  border-radius: 50%;
  color: var(--color-text);
  display: flex;
  font-size: 0.5rem;
  height: 1rem;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0.4rem;
  width: 1rem;
}

.btnStatusWrapper .btnStatus {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
}

.btnStatusWrapper .btnStatus:first-child {
  margin-left: 0;
}
.btnStatusWrapper .btnStatus:last-child {
  margin-right: 0;
}
.btnStatusWrapper .btnStatus:hover {
  border-color: var(--color-active);
}
.btnStatusWrapper .btnStatusActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}

.noSpaceLeft {
  padding-left: 0;
  padding-right: 0;
}

.label {
  color: var(--color-active);
  padding-bottom: 0.25em;
}

.filteredBetsContainer {
  display: flex;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  align-items: flex-end;
}
.filteredBetsContainer .resetBtn {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  margin: 0 10px;
  height: 2rem;
  min-width: 8rem;
  border: 1px solid;
}
.filteredBetsContainer .inputText {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
  width: 100%;
}
.filteredBetsContainer .inputText input {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
  padding-left: 0.5rem;
}
.filteredBetsContainer .inputText .inputProps:hover::before,
.filteredBetsContainer .inputText .inputProps:active::before {
  border-bottom-color: var(--color-active);
}

.spaceRight {
  margin-right: 0.5rem;
}

@media (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
  }

  .wrapper .mainContent {
    margin: 0px;
    width: 100%;
  }

  .wrapper .sideRight {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  .isSticky {
    border-top: 1px solid var(--color-text);
    bottom: 0;
    max-height: calc(100vh * 0.4);
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .wrapper .mainContent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .wrapper .sideRight {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 768px) {
  .wrapper .mainContent {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .wrapper .sideRight {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .spaceRight {
    margin-right: 0.5rem;
    width: calc(50% - 0.5rem);
  }
  .halfwidth {
    width: 50%;
  }
  .filteredBetsContainer .resetBtn {
    margin: unset;
    margin-top: 0.5rem;
  }
}
