.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
}

.wrapperRight {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.hideArrows input[type="number"]::-webkit-inner-spin-button,
.hideArrows input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.optionsWrapper {
  display: flex;
  flex-direction: row;
}

.optionsWrapper .customValueForm {
  width: 20%;
  margin-right: 0.5rem;
  padding: 0;
}

.optionsWrapper .customValueForm:last-child {
  margin-right: 0;
}

.option {
  color: var(--color-active);
  padding-bottom: 0.25em;
}

.wrapper .formWrapper {
  flex-direction: row;
}

.btnSaveContainer {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}
.btnSaveContainer .saveBtn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-secondary);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnSaveContainer .saveBtn:hover {
  border-color: var(--color-positive);
}

.btnSaveContainer .saveBtnActive {
  color: var(--color-positive);
}

.hide {
  visibility: hidden;
}

.relative {
  position: relative;
  width: 100%;
}

@media (max-width: 768px) {
  .wrapper {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
