@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap");

h1 {
  font-size: 2rem;
  font-weight: 700;
}

h2 {
  font-size: 1.75rem;
  margin: 1.25rem 0 1rem 0;
  color: var(--color-active);
  font-weight: 700;
}

h3 {
  font-size: 1.5rem;
  margin: 1rem 0 1rem 0;
  color: var(--color-active);
  font-weight: 700;
}

h4 {
  font-size: 1.25rem;
  margin: 0.75rem 0 0.5rem 0;
  color: var(--color-active);
  font-weight: 700;
}

p {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: 400;
  margin: 0;
  word-wrap: break-word;
}

a,
a:hover,
a:focus,
a:active,
a:visited {
  text-decoration: none;
  color: inherit;
}

select {
  font-family: "Roboto", sans-serif;
  font-size: inherit;
}

html {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.fontBold {
  font-weight: 700;
}

.fontLight {
  font-weight: 300;
}

.fontThin {
  font-weight: 100;
}

button {
  font-family: inherit;
}

@media (max-width: 480px) {
  html {
    font-size: 12px;
    /* orignal was: font-size: 12px; */
  }
}

@media (min-width: 480px) {
  html {
    font-size: 13px;
    /* orignal was: font-size: 13px; */
  }
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
    /* orignal was: font-size: 14px; */
  }
}

@media (min-width: 992px) {
  html {
    font-size: 15px;
    /* orignal was: font-size: 15px; */
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
    /* orignal was: font-size: 16px; */
  }
}

@media (min-width: 1600px) {
  html {
    font-size: 16px;
    /* orignal was: font-size: 16px; */
  }
}
