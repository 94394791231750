.wrapper {
  display: flex;
  background-color: var(--color-background-sidebar);
}
.mainContent {
  width: 70%;
  background-color: var(--color-background-main-selected);
  margin-right: 1rem;
  padding: 2rem;
}
.sideRight {
  margin-right: 1rem;
  width: 30%;
  background-color: var(--color-background-main);
  padding: 1rem 0.5rem;
}

.filtersContainer {
  display: flex;
  width: 100%;
}
.filtersContainer .filtersBody {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  justify-content: space-between;
  margin-left: -0.5rem;
  width: calc(100% + 1rem);
}

.filtersContainer .filtersHead {
  width: 100%;
  height: var(--height-button-secondary);
  display: flex;
  justify-content: flex-start;
  color: var(--color-text-secondary);
  padding: 0.5rem;
  padding-right: 0;
  border: 1px solid var(--color-text-secondary);
}
.filtersContainer .filtersHead.expanded {
  border: 0;
}
.filtersContainer .filtersHead .expandIcon {
  flex-grow: 1;
  text-align: right;
  width: 6%;
}

.traderRegionCompetitionContainer {
  min-width: calc(10rem * 3);
}

.traderRegionCompetitionContainer,
.marketPeriodContainer,
.scoretypeContainer,
.datePickerContainer,
.optionsContainer,
.resetBtnContainer {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.scoretypeContainer .scoreType,
.marketPeriodContainer .marketPeriod,
.tabsContainer .tab {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  background-color: var(--color-market-period);
  border-bottom: 2px solid var(--color-market-period);
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
}

.scoretypeContainer .scoreType:hover,
.marketPeriodContainer .marketPeriod:hover,
.tabsContainer .tab:hover {
  border-color: var(--color-active);
}

.scoretypeContainer .scoreTypeActive,
.marketPeriodContainer .marketPeriodActive,
.tabsContainer .tabActive {
  background-color: var(--color-background-header);
  color: var(--color-text);
  border-color: var(--color-active);
}

.optionsContainer .customValueForm {
  min-width: 6rem;
  margin: 0 1px;
  padding: 0;
}

.resetBtnContainer .resetBtn {
  text-transform: uppercase;
  color: var(--color-text-secondary);
  padding: 0.5rem 1rem;
  margin: 0 1px;
  height: 2rem;
  min-width: 8rem;
  border: 1px solid;
}

.resetBtnContainer .resetBtn:hover {
  background-color: var(--color-market-period);
  border-color: var(--color-market-period);
}

.resumeContainer {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 1rem;
  background-color: var(--color-score-type-container);
}

.resume {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.resumeHeader {
  color: var(--color-active);
  font-weight: 700;
  flex-wrap: wrap;
  padding-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  width: 100%;
  height: 23rem;
}

.resumeTitle,
.chartTitle {
  color: var(--color-active);
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.resumeTitle {
  margin-top: 2rem;
}

.linearChart {
  background-color: var(--color-score-type-container);
  color: var(--color-text);
  font-size: 0.8rem;
}

.footerNote {
  margin-top: 1rem;
  color: var(--color-text-secondary);
  font-style: inherit;
}

.dailyChangeContainer {
  padding-top: 0.5rem;
  width: 100%;
}

.resumeDetailsContainer {
  margin-top: 3rem;
}
.resumeDetailsContainer .wrapper {
  background-color: var(--color-score-type-container);
}
.resumeDetailsContainer .wrapper .chartTitle {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.betsContainer {
  margin-top: 3rem;
}

.resume .green {
  color: var(--color-positive);
}
.resume .red {
  color: var(--color-negative);
}

@media (max-width: 1200px) {
  .wrapper {
    /* display: inline; */
  }

  .wrapper .mainContent {
    width: 100%;
    margin-right: 0;
  }

  .filtersContainer {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .wrapper .sideRight {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }

  .traderRegionCompetitionContainer,
  .marketPeriodContainer,
  .scoretypeContainer,
  .datePickerContainer,
  .optionsContainer,
  .resetBtnContainer {
    padding: 0;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .wrapper .mainContent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .wrapper .sideRight {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 768px) {
  .wrapper .mainContent {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .wrapper .sideRight {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .filtersContainer {
    align-items: flex-start;
    flex-direction: column;
  }

  .traderRegionCompetitionContainer,
  .marketPeriodContainer,
  .scoretypeContainer,
  .datePickerContainer,
  .optionsContainer {
    min-width: 100%;
  }

  .scoretypeContainer .scoreType,
  .marketPeriodContainer .marketPeriod {
    min-width: calc(calc(100% - 8px) / 4);
  }

  .datePickerContainer button {
    min-width: calc(calc(100% - 4px) / 4);
  }

  .optionsContainer .customValueForm {
    max-width: 8rem;
  }

  /* .resetBtnContainer {
    align-self: flex-end;
  } */

  .resumeContainer {
    padding: 0.5rem;
  }

  .resume {
    padding: 0 0.2rem;
  }

  .traderRegionCompetitionContainer,
  .marketPeriodContainer,
  .scoretypeContainer,
  .datePickerContainer,
  .optionsContainer,
  .resetBtnContainer {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .resumeContainer {
    flex-wrap: wrap;
  }

  .resume {
    justify-content: flex-end;
    margin: 0.5rem 0;
    min-width: calc(100% / 3);
  }

  .filtersContainer .filtersBody {
    padding: 0.5rem;
    width: 100%;
    margin-left: 0;
    background-color: var(--color-text-secondary-transparent);
  }
}
