.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main-selected);
  width: 100%;
  padding: 1rem;
  border: 1px solid var(--color-text-secondary-transparent); /* style of popup over popup */
}

.header {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-bottom: 1rem;
  justify-content: space-between;
}

.title {
  color: var(--color-active);
  display: flex;
  width: 100%;
  min-height: 1.8rem;
  align-items: baseline;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.title .main {
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0;
}

.content {
  border-bottom-style: solid;
  border-bottom-color: var(--color-background-main);
  padding-bottom: 1em;
}

.content .row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.content .row .width50 {
  width: calc(50% - 0.5rem);
}

.content .label {
  color: var(--color-active) !important;
  padding-bottom: 0.25em;
}

.btnCloseContainer {
  display: flex;
  justify-content: flex-end;
  border-top: 4px solid var(--color-background-main);
  padding: 1em 0em 2em 2em;
}

.btnCloseContainer .btn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
  margin: 0 0.5rem;
}
.btnCloseContainer .btn:first-child {
  margin-left: 0;
}
.btnCloseContainer .btn:last-child {
  margin-right: 0;
}

.btnCloseContainer .btn.btnCancel {
  color: var(--color-text-miscellaneous);
}

.btnCloseContainer .btn.btnCancel:hover {
  border-color: var(--color-text-miscellaneous);
}

.btnCloseContainer .btn.saveBtn {
  color: var(--color-positive);
}
.btnCloseContainer .btn.saveBtn.isDisabled {
  color: var(--color-text-secondary);
}

.btnCloseContainer .btn.saveBtn:hover {
  background-color: var(--color-background-header);
  border-color: var(--color-positive);
}

.btnCloseContainer .btn.saveBtn svg {
  font-size: 1.5em;
  margin-right: 0.25rem;
}

/* START input text */
.addCompetitor {
  margin-bottom: 1.5rem;
  width: 100%;
}
.addCompetitor .textInput {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
}
.addCompetitor .underline:after {
  border-bottom-color: var(--color-active) !important;
}
.addCompetitor .underline:hover::before,
.addCompetitor .underline:active::before,
.addCompetitor .underline:focus::before,
.addCompetitor .underline:hover::after,
.addCompetitor .underline:active::after,
.addCompetitor .underline:focus::after {
  border-bottom-color: var(--color-active) !important;
}
/* END input text */
