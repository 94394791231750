.competitionsOverview {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
}

.loadingParent {
  position: relative;
  min-height: 6rem;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  width: 100%;
}

.title {
  color: var(--color-positive);
  font-weight: 400;
  text-transform: uppercase;
}

.title .note {
  color: var(--color-text-secondary);
  text-transform: lowercase;
  font-size: 0.85em;
  padding-left: 0.5em;
}

.header .close {
  font-size: 1.4rem;
  padding: 0.25rem;
}
.header .close:hover {
  color: var(--color-positive);
}

.header .close:hover {
  color: var(--color-positive);
}

.tableHead {
  align-items: center;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  color: var(--color-text-secondary);
  display: flex;
  flex-direction: row;
  min-height: 1.8rem;
  text-transform: uppercase;
  width: 100%;
}

.tableHead .region {
  justify-content: center;
}

.sortIcon {
  display: flex;
  flex-direction: column;
  height: 1.8rem;
  width: 1.3em;
}

.sortIcon .down {
  color: var(--color-text);
  height: 50%;
  transform: translateY(-3px);
  width: 100%;
}

.sortIcon .up {
  color: var(--color-text);
  transform: translateY(3px) rotate(180deg);
  height: 50%;
  width: 100%;
}

.sortIcon .down.active,
.sortIcon .up.active {
  color: var(--color-active);
}

.competitions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.wrapper .competitionWrapper {
  align-items: center;
  background-color: var(--color-score-type-container);
  border-bottom: 1px solid var(--color-background-main-selected);
  display: flex;
  flex-direction: row;
  font-size: 0.85em;
  min-height: 1.8rem;
  width: 100%;
}

.wrapper .competitionWrapper:hover {
  background-color: var(--color-active-parent-hover);
}

.wrapper .competitionWrapper:hover .expandIcon {
  color: var(--color-positive);
}

.wrapper .competitionWrapper.expanded {
  background-color: var(--color-active-parent);
}

.region {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 15%;
}

.region .flag {
  padding: 0 0.5rem 0 1rem;
}

.name {
  text-align: center;
  flex-grow: 1;
}

.stake {
  text-align: center;
  width: 12%;
}

.profit {
  text-align: center;
  width: 14%;
}

.margin {
  text-align: center;
  width: 12%;
}

.bets {
  text-align: center;
  width: 8%;
}

.expandIcon {
  text-align: right;
  width: 6%;
}

.green {
  color: var(--color-positive);
}
.red {
  color: var(--color-negative);
}

.zeroBetsContainer {
  min-height: 5rem;
  color: var(--color-text-secondary);
  background-color: var(--color-event-markets);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.85em;
}

.zeroBetsContainer .text {
  opacity: 0.5;
}

.zeroBetsContainer .big {
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.2em 0;
}

@media (max-width: 768px) {
  .tableHead {
    font-size: 0.7rem;
  }

  .tableHead .region div {
    display: none;
  }

  .region {
    width: 5%;
  }

  .region .flag {
    padding: 0 0.25em 0 0.25em;
  }

  .region .regionName {
    display: none;
  }

  .name {
    text-align: left;
  }

  .stake {
    width: 14%;
  }

  .profit {
    width: 18%;
  }

  .margin {
    width: 14%;
  }

  .bets {
    width: 12%;
  }
}

@media (max-width: 410px) {
  .tableHead {
    font-size: 0.6rem;
  }

  .headerTitle {
    height: 100%;
    word-break: break-word;
  }

  .name {
    max-width: 30%;
  }
}
