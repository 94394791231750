.wrapper {
  display: flex;
  background-color: var(--color-background-sidebar);
}
.mainContent {
  width: 70%;
  background-color: var(--color-background-main-selected);
  margin-right: 1rem;
  padding: 2rem;
}
.sideRight {
  margin-right: 1rem;
  width: 30%;
  background-color: var(--color-background-main);
  padding: 1rem 0.5rem;
}
.filtersContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 1rem;
}

@media (max-width: 1200px) {
  .wrapper {
    flex-direction: column;
  }
  .wrapper .mainContent {
    margin: 0px;
    width: 100%;
  }

  .wrapper .sideRight {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .wrapper .mainContent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .wrapper .sideRight {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 768px) {
  .wrapper .mainContent {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .wrapper .sideRight {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}
