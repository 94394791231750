.wrapperNarrow {
  display: flex;
  padding: 4rem 1rem;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.column {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.wrapperNarrow .inputField {
  color: var(--color-text);
  margin: 0.5rem 0 1.5rem 0;
}
.wrapperNarrow .inputField .myInput {
  color: var(--color-text);
}
.wrapperNarrow .inputField .myInputRoot:hover .myInput,
.wrapperNarrow .inputField .isFocused .myInput {
  background-color: var(--color-background-header);
}
.wrapperNarrow .inputField .myInputRoot:hover .myOutline,
.wrapperNarrow .inputField .isFocused .myOutline,
.wrapperNarrow .inputField .myOutline {
  border-color: var(--color-text);
}
.wrapperNarrow .inputField .myLabel {
  color: var(--color-text);
}
.wrapperNarrow .inputField .myHelper {
  color: var(--color-text-secondary);
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}
.wrapperNarrow .inputField .hasError .myOutline,
.wrapperNarrow .inputField .hasError.myHelper {
  color: var(--color-negative);
}

.wrapperNarrow .loginBtn {
  color: var(--color-text);
  background-color: var(--color-active);
}
.wrapperNarrow .loginBtn:hover {
  background-color: var(--color-active);
}
.wrapperNarrow .loginBtn.isDisabled {
  color: var(--color-text);
  background-color: var(--color-text-secondary);
}
