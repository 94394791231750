.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);
  width: 100%;
  padding-top: 1rem;
}

.wrapperRight {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
}

.hideArrows input[type="number"]::-webkit-inner-spin-button,
.hideArrows input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  display: none;
}

.optionsWrapper {
  display: flex;
  flex-direction: row;
}

.optionContainer {
  width: 100%;
  padding-left: 1rem;
  padding-right: 0.5rem;
}

.option {
  color: var(--color-active);
  padding-bottom: 0.25em;
}

.wrapper .formWrapper {
  flex-direction: row;
}

.optionContainer .inputText {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);

  width: 100%;
}

.optionContainer .inputText input {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
  text-align: center;
}

.optionContainer.invalid .inputText input {
  color: rgb(255, 255, 255);
  background-color: red;
}

.optionContainer .inputSelection {
  color: var(--color-text);
  background-color: var(--color-background-sidebar);
  text-align-last: center;
  width: 100%;
  height: var(--height-button-secondary);
}

.optionContainer .inputSelection select {
  padding-left: 0.3em;
  padding-right: 1.6em;
}

.optionContainer .inputSelection .selectOption {
  background-color: var(--color-background-sidebar);
}

.btnSaveContainer {
  display: flex;
  justify-content: flex-end;
  margin: 1rem 0;
  padding-right: 2rem;
}
.btnSaveContainer .saveBtn {
  background-color: var(--color-background-sidebar);
  color: var(--color-text-secondary);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnSaveContainer .saveBtnActive {
  color: var(--color-positive);
}

.optionContainer .arrowsContainer {
  display: flex;
  flex-direction: column;
  right: 0;
  top: 0;
  height: 100%;
  justify-content: center;
  width: 1rem;
  align-items: center;
  padding-right: 2px;
  position: absolute;
}

.optionContainer .arrowsContainer .arrow {
  height: 50%;
  width: 1rem;
  padding: 2px 1px;
}

.optionContainer .arrowsContainer .arrow .up {
  transform: rotate(180deg);
}

.hide {
  visibility: hidden;
}

.relative {
  position: relative;
}

.optionContainer .selectIcon {
  color: inherit;
}

.optionContainer .selectIcon.isDisabled {
  color: var(--color-text-secondary);
}
.optionContainer .isDisabled {
  color: var(--color-text-secondary);
}

.optionContainer .inputSelection:hover:not(.Mui-disabled):before {
  border-color: var(--color-active);
}

.optionContainer .inputText .inputProps:hover::before,
.optionContainer .inputText .inputProps:active::before {
  border-bottom-color: var(--color-active);
}

.optionContainer .inputText .inputProps p {
  color: var(--color-text);
}
