.wrapper {
  display: flex;
}

.noSpaceLeft {
  padding-left: 0;
  padding-right: 0;
  margin: 0 1px;
  width: 33%;
}
