.header {
  align-items: center;
  display: flex;
  background-color: var(--color-background-header);
}

.header .sidebarBtn {
  display: none;
  padding: 0.5rem;
  width: var(--width-main-sidebar-left);
  height: var(--width-main-sidebar-left);
}

.logoContainer {
  display: flex;
  width: var(--width-main-sidebar-left);
  height: var(--width-main-sidebar-left);
}

.logoContainer .icon {
  height: 100%;
  padding: 0.5rem;
  width: 100%;
}

.centerContainer {
  display: flex;
  flex-grow: 1;
  padding: 0.5rem;
  align-items: center;
}

.centerContainer .logo {
  height: calc(var(--width-main-sidebar-left) - 1rem);
  padding: 0.25rem;
  width: auto;
}

.centerContainer .logout {
  margin-left: auto;
  padding: 0.5rem;
  text-transform: uppercase;
  border-radius: 2px;
}

.centerContainer .logout:hover {
  background-color: var(--color-active);
}

@media (max-width: 768px) {
  .header .sidebarBtn {
    display: flex;
  }
}
