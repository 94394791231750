.wrapperCompetition {
  display: flex;
  flex-direction: column;
}

.wrapperCompetition .competition {
  display: flex;
  width: 100%;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.8rem;
  align-items: center;
  justify-content: flex-start;
}
.wrapperCompetition .competition:hover,
.wrapperCompetition .competition.expanded {
  background-color: var(--color-background-sidebar);
}
.wrapperCompetition .competition .region {
  display: flex;
  align-items: center;
  width: 25%;
  min-width: 13rem;
  color: var(--color-active);
}
.competition .flag {
  display: flex;
  padding: 0.2rem 0.3rem 0.2rem 0.5rem;
}
.competition .favoriteIcon {
  margin-right: 0.25rem;
  color: var(--color-active);
}
.competition .competitionName {
  flex-grow: 1;
  color: var(--color-active);
}
.competition .expandIcon {
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
}
.wrapperCompetition .competition:hover .favoriteIcon:not(:hover) ~ .expandIcon,
.competition .favoriteIcon:hover {
  color: var(--color-positive);
}

.wrapperCompetition .isSticky {
  position: sticky;
  top: var(--width-main-sidebar-left);
  z-index: 4;
}

@media (max-width: 480px) {
  .competition .flag {
    padding: 0.2rem;
  }

  .competition .region {
    min-width: 8rem;
  }

  .competition .expandIcon {
    padding: 0;
  }
}

@media (max-width: 360px) {
  .competition .region {
    min-width: 7rem;
  }
}
