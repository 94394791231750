.mainWrapper {
  display: flex;
  background-color: var(--color-background-sidebar);
}

.mainContent {
  width: 70%;
  background-color: var(--color-background-main-selected);
  margin-right: 1rem;
  padding: 2rem;
}

.traderContainer {
  display: flex;
  width: 20%;
  padding-bottom: 1rem;
  min-width: 8rem;
}

.traderContainer .noSpaceLeft {
  padding-left: 0;
  padding-right: 0;
}

.rightSide {
  margin-right: 1rem;
  width: 30%;
  background-color: var(--color-background-main);
  padding: 1rem 0.5rem;
}

.row {
  display: flex;
  flex-direction: row;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.tab {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #444444;
  color: var(--color-light-blue);
}

.title3 {
  color: var(--color-active);
  line-height: 1.8em;
  padding-top: 0.5em;
  font-weight: 700;
}

.tabDiv {
  padding-left: 0.5rem;
  background-color: #444444;
  color: var(--color-light-blue);
}

.chartsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.chartContainer {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  width: calc(50% - 1rem);
  height: 23rem;
}

.chartTitle {
  color: var(--color-active);
  font-weight: 700;
  padding-bottom: 0.5rem;
}

.linearChart {
  background-color: var(--color-score-type-container);
  color: var(--color-text);
  font-size: 0.8rem;
}

.scrollableContainer {
  width: 100%;
}

.overViewTable {
  display: flex;
  flex-direction: column;
  min-width: 680px;
}

.statName {
  width: 12%;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 0.5rem;
}

.statValue {
  width: 11%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statValue .btn {
  border: 1px solid var(--color-positive);
  border-radius: 4px;
  font-size: 0.8rem;
  min-height: 90%;
  padding: 0.25rem 0.5rem;
  min-width: 80%;
  max-width: 100%;
}

.statValue .btn:hover,
.statValue .btn.active {
  background-color: var(--color-positive);
  color: var(--color-text);
}

.tableHead {
  display: flex;
  width: 100%;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.8rem;
  align-items: center;
  text-transform: uppercase;
  color: var(--color-text-secondary);
  text-align: center;
}

.tableLine {
  display: flex;
  width: 100%;
  font-size: 0.9em;
  min-height: 1.8rem;
  background-color: var(--color-score-type-container);
  border-bottom: 1px solid var(--color-background-main-selected);
}
.tableLine:hover {
  background-color: var(--color-background-header);
}

.footerNote {
  margin-top: 4rem;
  color: var(--color-text-secondary);
  font-style: inherit;
}

.green {
  color: var(--color-positive);
}
.red {
  color: var(--color-negative);
}

@media (max-width: 1200px) {
  .mainWrapper {
    display: inline;
  }

  .mainContent {
    width: 100%;
    margin-right: 0;
  }

  .rightSide {
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 0rem;
  }
}

@media (max-width: 992px) {
  .mainContent {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .rightSide {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 768px) {
  .mainContent {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .statValue .btn {
    max-width: 100%;
  }
  .scrollableContainer {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .rightSide {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .chartsContainer {
    display: inline;
  }

  .chartContainer {
    width: 100%;
    margin-bottom: 2rem;
  }

  .chartContainer:last-child {
    padding-bottom: 0rem;
  }

  .footerNote {
    margin-top: 2rem;
  }

  .OverViewTable {
    width: 100%;
    padding-bottom: 2rem;
  }
}
