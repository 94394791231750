.wrapper {
  display: flex;
  align-items: center;
}
.wrapper .iconArrow {
  height: 2rem;
}
.wrapper .iconArrow:hover {
  color: var(--color-active);
}
.wrapper .datePickerInput {
  padding: 0.5rem 1rem;
  height: 2rem;
  min-width: 8rem;
  margin: 0 1px;
  background-color: var(--color-background-sidebar);
}
.wrapper .datePickerInput:hover {
  background-color: var(--color-background-header);
  color: var(--color-active);
}

.wrapper .datepickerIsOpen .datePickerInput {
  color: var(--color-active);
}
