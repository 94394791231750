.wrapperTable {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.tableHead {
  display: flex;
  width: 100%;
  background-color: var(--color-background-header);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.8rem;
  align-items: center;
  text-transform: uppercase;
  color: var(--color-text-secondary);
  text-align: center;
}

.tableLine {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  font-size: 0.9em;
  min-height: 1.8rem;
  background-color: var(--color-score-type-container);
  border-bottom: 1px solid var(--color-background-main-selected);
}
.tableLine:hover {
  background-color: var(--color-background-header);
}

.cell {
  width: calc(100% / 5);
  padding: 0.2rem 0.5rem;
}

.left {
  text-align: left;
}

.tableLine .green {
  color: var(--color-positive);
}
.tableLine .red {
  color: var(--color-negative);
}

.loadingParent {
  position: relative;
  min-height: 15rem;
}

.loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}
