.row {
  display: flex;
  flex-direction: row;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.column {
  display: flex;
  flex-direction: column;
}

.tab {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #444444;
  color: var(--color-light-blue);
}

.tabDiv {
  padding-left: 0.5rem;
  background-color: #444444;
  color: var(--color-light-blue);
}

.body {
  background-color: #444444;
}
