.wrapper {
  display: flex;
  align-items: center;
}
.wrapper .iconArrow {
  height: 2rem;
}
.wrapper .iconArrow:hover {
  color: var(--color-active);
}
.wrapper .datePickerInput {
  padding: 0rem 2rem;
  height: 2rem;
  margin: 0 1px;
  background-color: var(--color-background-sidebar);
  display: flex;
}
.wrapper .datePickerInput:hover {
  background-color: var(--color-background-header);
  color: var(--color-active);
}

.wrapper .datepickerIsOpen .datePickerInput {
  color: var(--color-active);
}

.wrapper .isActive {
  color: var(--color-active);
}

.wrapper .row {
  display: flex;
  flex-wrap: nowrap;
}

.wrapper .column {
  display: flex;
  flex-direction: column;
}

.wrapper .modalDatePickerRange {
  min-width: unset;
  background-color: var(--color-background-main);
  padding: 1rem;
}

.modalDatePickerRange .title {
  color: var(--color-active);
  font-size: 1.5rem;
  line-height: 1em;
  font-weight: 700;
  margin: 0.2em 0 0.5em 0;
}
.modalDatePickerRange .subtitle {
  color: var(--color-active);
  padding: 0.5em 0rem;
}

.modalDatePickerRange .calendarWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -0.5em;
  width: calc(100% + 1em);
}

.modalDatePickerRange .calendarItem {
  padding-left: 0.5em;
  padding-right: 0.5em;
  padding-bottom: 1em;
}

.btnContainer {
  display: flex;
  justify-content: flex-end;
  border-top: 4px solid var(--color-background-main-selected);
  padding: 1em 0em 2em 2em;
}

.btnContainer .btnCancel {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-miscellaneous);
  padding: 0.5rem 4rem;
  margin-right: 1em;
  text-transform: uppercase;
}

.btnContainer .btnCancel:hover {
  border-color: var(--color-text-miscellaneous);
}

.btnContainer .btn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-secondary);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnContainer .btn:hover {
  border-color: var(--color-positive);
}

.btnContainer .btnActive {
  color: var(--color-positive);
}
