.betInfoTable {
  display: flex;
  flex-direction: column;
}

.betInfoTable .betInfoHeader {
  display: flex;
  width: 100%;
  background-color: var(--color-event-markets);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.6rem;
  align-items: center;
  color: var(--color-active);
  justify-content: flex-start;
  text-transform: uppercase;
}

.betInfoTable .betInfoWrapper {
  display: flex;
  width: 100%;
  background-color: var(--color-event-markets);
  border-bottom: 1px solid var(--color-background-main-selected);
  min-height: 1.6rem;
  align-items: center;
  justify-content: flex-start;
}
.betInfoTable .betInfoWrapper:hover {
  background-color: var(--color-background-main-selected);
}

.betInfoTable .betInfoWrapper.betTotals {
  background-color: var(--color-background-main);
  color: var(--color-active);
}

.betInfoTable .betInfoWrapper.betTotals:hover {
  background-color: var(--color-active-parent-hover);
}

.date {
  width: 8%;
  padding: 0.2rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.trader {
  width: 10%;
  display: flex;
  justify-content: flex-start;
}

.marketProvider {
  width: 9%;
  text-align: left;
}

.market {
  width: 9%;
  text-align: left;
}

.marketSelected {
  flex-grow: 1;
  text-align: left;
}

.line {
  width: 8%;
  text-align: right;
}

.price {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 8%;
}

.stake {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  width: 8%;
}

.stake .openBetStake {
  color: var(--color-text-secondary);
}

.price.hasMatchedBet .requestedPrice,
.stake.hasMatchedBet .requestedStake {
  text-decoration: line-through;
  cursor: help;
  color: var(--color-text-secondary);
}
.price.hasMatchedBet .bookiePrice,
.stake.hasMatchedBet .bookieStake {
  cursor: help;
}

.value {
  text-align: right;
  width: 7%;
}

.profit {
  text-align: right;
  width: 8%;
}

.status {
  width: 8%;
  text-align: right;
  padding-right: 0.5rem;
}

.bookieStatus {
  text-align: right;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-right: 0.5rem;
  width: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.bookieStatus .grayText {
  font-size: 0.7em;
}

.hide_xl {
  display: none;
}

.green {
  color: var(--color-positive);
}
.red {
  color: var(--color-negative);
}

.scrollableContainer {
  width: 100%;
}

.profitLoss {
  background-color: var(--color-background-main);
  border-radius: 4px;
  color: var(--color-active);
  line-height: 1.8em;
  padding: 0.25em 0.3em;
  font-weight: 700;
  margin-left: 0.6em;
}

.hide {
  display: none;
}

.grayText {
  color: var(--color-text-secondary);
}

.isDeletedStyle {
  color: var(--color-text-secondary);
}
.isDeletedStyle .grayText {
  opacity: 0.65;
}

.zeroBetsContainer {
  min-height: 5rem;
  color: var(--color-text-secondary);
  background-color: var(--color-event-markets);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.zeroBetsContainer .text {
  opacity: 0.5;
}

.zeroBetsContainer .big {
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.2em 0;
}

@media (max-width: 768px) {
  .betInfoTable {
    padding: 0;
  }
}
.scrollableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.betInfoTable .betInfoHeader > div,
.betInfoTable .betInfoWrapper > div {
  padding-left: 0.2em;
  padding-right: 0.2em;
}

@media (max-width: 480px) {
  .betInfoTable .betInfoHeader div {
    font-size: 0.7em;
  }

  .date {
    width: 12%;
    padding-right: 0.2rem;
  }

  .trader {
    width: 13%;
  }

  .marketSelected {
    width: 10%;
  }

  .betInfoTable .betInfoWrapper div {
    font-size: 0.7em;
  }

  .hide_sm {
    display: none;
  }

  .hide_xl {
    display: block;
  }
}
