.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-main);
  width: 100%;
  padding: 1rem;
}

.message {
  padding-bottom: 1rem;
  align-self: center;
  padding: 1rem 0rem 2rem;
}

.btnContainer {
  display: flex;
  align-self: center;
  justify-content: flex-end;
  border-top: 4px solid var(--color-background-main-selected);
  padding: 2em 0em 1em 0em;
}

.btnContainer .cancelBtn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-text-miscellaneous);
  padding: 0.5rem 4rem;
  margin-right: 1em;
  text-transform: uppercase;
}

.btnContainer .cancelBtn:hover {
  border-color: var(--color-text-miscellaneous);
}

.btnContainer .deleteBtn {
  background-color: var(--color-background-sidebar);
  border-bottom: 1px solid var(--color-background-sidebar);
  color: var(--color-negative-grid);
  padding: 0.5rem 4rem;
  text-transform: uppercase;
}

.btnContainer .deleteBtn:hover {
  border-color: var(--color-negative-grid);
}

.hide {
  visibility: hidden;
}

.relative {
  position: relative;
  width: 100%;
}

.pair {
  display: flex;
  align-items: center;
  width: 100%;
}

.settleName {
  padding: 0 1rem;
  flex-grow: 1;
  text-align: center;
  color: var(--color-text-secondary);
  font-size: 0.85em;
}

.type {
  flex-direction: column;
  padding-bottom: 0.5em;
}

.settlementHeader {
  padding-left: 2rem;
  padding-bottom: 0.5rem;
  color: var(--color-active);
  font-size: 1.5em;
  line-height: 1em;
}

.settlementEvent {
  padding-left: 2rem;
  color: var(--color-active);
  font-size: 1em;
}
